import { API_ORIGIN as BASE_URL } from "../utils/Environment";

export async function logout(logoutToken, accessToken) {
  try {
    const csrfToken = await fetch(`${BASE_URL}/session/token`);
    const csrfTokenData = await csrfToken.json();
    const csrfTokenValue = csrfTokenData._csrfToken;

    const response = await fetch(
      `${BASE_URL}/user/logout?_format=json&token=${logoutToken}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfTokenValue,
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(response.message || "Could not logout.");
    }
    return response;
  } catch (error) {
    // Handle error
  }
}

export async function login(credential) {
  const response = await fetch(`${BASE_URL}/user/login?_format=json`, {
    method: "POST",
    body: JSON.stringify(credential),
  });
  return response;
}

export async function contactUs(formData) {
  const response = await fetch(`${BASE_URL}/entity/contact_message`, {
    method: "POST",
    headers: {
      Authorization: `Bearer /entity/contact_message`,
      "Content-type": "application/json",
    },
    body: JSON.stringify(formData),
  });
  return response;
}

export async function videoRating(currentUser, formData) {
  const response = await fetch(`${BASE_URL}/comment?_format=json`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-type": "application/json",
    },
    body: JSON.stringify(formData),
  });
  return response;
}

export async function saveVideoDuration(currentUser, formData) {
  const response = await fetch(`${BASE_URL}/jsonapi/flagging/media_play_back`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-type": "application/vnd.api+json",
    },
    body: JSON.stringify(formData),
  });
  return response;
}

export async function updateNotificationRead(currentUser, formData) {
  const response = await fetch(`${BASE_URL}/jsonapi/flagging/contact_message_read_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-type": "application/vnd.api+json",
    },
    body: JSON.stringify(formData),
  });
  return response;
}

export async function getNotification(currentUser, notificationId) {
  let id = notificationId ? `/${notificationId}` : "";
  const response = await fetch(
    `${BASE_URL}/jsonapi/message/notification${id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.access_token}`,
        "Content-type": "application/json",
      },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getSupportCategory(currentUser) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/attribute/support/category`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.access_token}`,
        "Content-type": "application/json",
      },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getSupportSubCategory(currentUser, tid) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/attribute/support/subcategory/${tid}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.access_token}`,
        "Content-type": "application/json",
      },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function register(formData) {
  const response = await fetch(`${BASE_URL}/user/registerpass?_format=json`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.message || "Could not create quote.");
  }
  return data;
}

export async function validateEmail(email) {
  const response = await fetch(`${BASE_URL}/jsonapi/user/email/${email}?_format=json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getBannerDetails(currentUser) {
  if (currentUser === null) {
    const response = await fetch(
      `${BASE_URL}/jsonapi/banner/banner-before-login?_format=json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  } else {
    const response = await fetch(
      `${BASE_URL}/jsonapi/banner/banner-after-login?_format=json`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${currentUser.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  }
}

export async function getSpeciality(profession_id) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/attribute/speciality/${profession_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getProfessional() {
  const response = await fetch(`${BASE_URL}/jsonapi/attribute/profession`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getCategory() {
  const response = await fetch(`${BASE_URL}/jsonapi/attribute/category`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getPeerSearch() {
  const response = await fetch(`${BASE_URL}/jsonapi/search/keywords`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getUserTokenStatus(currentUser) {
  const response = await fetch(`${BASE_URL}/user/login_status?_format=json`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getFeaturedVideos() {
  const response = await fetch(
    `${BASE_URL}/jsonapi/videos/featured?_format=json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getAutocompleteKeywords() {
  const response = await fetch(`${BASE_URL}/search/index_list?_format=json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getWhatsNew(currentUser) {
  if (currentUser === null) {
    const response = await fetch(`${BASE_URL}/jsonapi/videos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  } else {
    const response = await fetch(`${BASE_URL}/jsonapi/protected/videos`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.access_token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  }
}


export async function getUserActivation(userId, userToken) {
  const response = await fetch(`${BASE_URL}/api/user/registration/confirm/${userId}/${userToken}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getVideoDetails(path_name, currentUser) {
  if (currentUser === null) {
    const response = await fetch(`${BASE_URL}/jsonapi/videos/detail?alias=/${path_name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  } else {
    const response = await fetch(
      `${BASE_URL}/jsonapi/protected/videos/details?alias=/${path_name}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${currentUser.access_token}`,
          "Content-type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  }
}

export async function getHighlightsVideos() {
  const response = await fetch(`${BASE_URL}/jsonapi/highlights`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getCategoryVideos(category_name, currentUser) {
  if (currentUser === null) {
    const response = await fetch(
      `${BASE_URL}/jsonapi/videos/category?catq=${category_name}&_format=json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  } else {
    const response = await fetch(
      `${BASE_URL}/jsonapi/protected/videos/category?catq=${category_name}&_format=json`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${currentUser.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch quotes.");
    }
    return data;
  }
}

export async function getSearchAllVideos(search_value) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/search/${search_value}?_format=json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getHistoryVideos(currentUser) {
  const response = await fetch(`${BASE_URL}/jsonapi/history`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getWatchLaterVideos(currentUser) {
  const response = await fetch(`${BASE_URL}/jsonapi/watch_later`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getEncryptVideoUrl(videoMid) {
  const response = await fetch(`${BASE_URL}/api/preview/url/${videoMid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getMyFavouritesVideos(currentUser) {
  const response = await fetch(`${BASE_URL}/jsonapi/favorite`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getAuthorVideos(author_name) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/videos/author/${author_name}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getNeedHelp() {
  const response = await fetch(`${BASE_URL}/jsonapi/need-help`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getVideoSubmit() {
  const response = await fetch(`${BASE_URL}/jsonapi/submit-your-video`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getsubCategory(categoryId) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/attribute/subcategory/${categoryId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getPages() {
  const response = await fetch(
    `${BASE_URL}/jsonapi/basiclandingpages?_format=json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getPagesData(page_title) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/basiclandingpages/detail?alias=/${page_title}&_format=json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getUserProfile(accessToken, uid) {
  const response = await fetch(
    `${BASE_URL}/jsonapi/user/profile/?filter[uid]=${uid}&include=roles,profession,specialty&jsonapi_include=1`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function updateUserProfile(accessToken, uid, updateData) {
  const response = await fetch(`${BASE_URL}/user/${uid}?_format=json`, {
    method: "PATCH",
    body: JSON.stringify(updateData),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok && response.status === 422) {
    throw new Error("Your current password is invalid. Please enter the valid current password.");
  } else if (!response.ok && !response.status === 422) {
    throw new Error(data.message || "Something went wrong!")
  }
  return data;
}

export async function getAuthorList() {
  const response = await fetch(`${BASE_URL}/jsonapi/masters?_format=json`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getAuthorDetail(authorid) {
  const response = await fetch(`${BASE_URL}/jsonapi/masters/detail/${authorid}?_format=json`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getInterestedCategories(accessToken) {
  const response = await fetch(`${BASE_URL}/api/interested_categories`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addInterestCategories(accessToken, uid, categoriesId) {
  const response = await fetch(
    `${BASE_URL}/api/interested_categories?uid=${uid}`,
    {
      method: "POST",
      body: JSON.stringify(categoriesId),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addVideoHistory(accessToken, videodetails) {
  const response = await fetch(`${BASE_URL}/api/history`, {
    method: "POST",
    body: JSON.stringify(videodetails),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addKeywordHistory(currentUser, formData) {
  const response = await fetch(`${BASE_URL}/jsonapi/taxonomy_term/search_api_top_keyword`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      Authorization: `Bearer ${currentUser.access_token}`,
      "Content-type": "application/vnd.api+json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addFavourites(accessToken, videodetails) {
  const response = await fetch(`${BASE_URL}/api/favorite`, {
    method: "POST",
    body: JSON.stringify(videodetails),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function emailPasswordLinkSend(emailData) {
  const response = await fetch(`${BASE_URL}/user/lost-password?_format=json`, {
    method: "POST",
    body: JSON.stringify(emailData),
    headers: {
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function resetPasswordUpdate(passwordData) {
  const response = await fetch(`${BASE_URL}/user/lost-password-reset?_format=json`, {
    method: "POST",
    body: JSON.stringify(passwordData),
    headers: {
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function passwordTimeUpdate(passwordTimeData) {
  const response = await fetch(`${BASE_URL}/api/terms/services`, {
    method: "PATCH",
    body: JSON.stringify(passwordTimeData),
    headers: {
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function removeFavourites(accessToken, mid, uid) {
  const response = await fetch(`${BASE_URL}/api/favorite/${mid}/${uid}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addWatchLater(accessToken, videodetails) {
  const response = await fetch(`${BASE_URL}/api/watch_later`, {
    method: "POST",
    body: JSON.stringify(videodetails),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function removeWatchLater(accessToken, mid, uid) {
  const response = await fetch(`${BASE_URL}/api/watch_later/${mid}/${uid}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function updateUserPersonaStatus(accessToken, uid, updateData) {
  const response = await fetch(`${BASE_URL}/user/${uid}?_format=json`, {
    method: "PATCH",
    body: JSON.stringify(updateData),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  return response;
}

export async function getQuestionnaire(accessToken, uid) {
  const response = await fetch(`${BASE_URL}/api/questionnaire/${uid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function getQuestionList(accessToken) {
  const response = await fetch(`${BASE_URL}/api/question_list`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}

export async function addAnswer(accessToken, formData) {
  const response = await fetch(`${BASE_URL}/api/questionnaire`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch quotes.");
  }
  return data;
}
